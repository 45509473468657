import htmx from 'htmx.org';
window.htmx = htmx;

import 'flowbite';
import 'htmx-ext-ws';

import '../tailwindcss/main.scss';

const initializePolicyAgree = () => {
    const value: string = localStorage.getItem('policyAgree') ?? '';
    const policyAgreeWindow = document.querySelector<HTMLElement>('.policyAgree');
    const policyAgreeButton = document.querySelector<HTMLElement>('.policyAgree__button');

    if (value !== 'accepted') {
        policyAgreeWindow?.classList.remove('hidden');
        policyAgreeWindow?.classList.add('flex');
    }

    policyAgreeButton?.addEventListener('click', () => {
        policyAgreeWindow?.classList.remove('flex');
        policyAgreeWindow?.classList.add('hidden');
        localStorage.setItem('policyAgree', 'accepted');
    });
};

document.addEventListener('DOMContentLoaded', initializePolicyAgree);
